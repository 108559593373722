import React from 'react'
import './testimonials.css'
import AVATAR1 from '../../assets/avatar1.jpg'
import AVATAR2 from '../../assets/avatar2.jpg'
import AVATAR3 from '../../assets/avatar3.jpg'
import AVATAR4 from '../../assets/avatar4.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data=[
  {avatar: AVATAR1,
  name: 'Richard P. Feynman',
review:'I have approximate answers, and possible beliefs, and different degrees of certainty about different things, but I’m not absolutely sure of anything.'},
{avatar: AVATAR2,
  name: 'Jesse L. Livermore',
review:'There is nothing new in Wall Street. There can’t be because speculation is as old as the hills. Whatever happens in the stock market today has happened before and will happen again.'},
{avatar: AVATAR3,
  name: 'Georg Cantor',
review:'In mathematics the art of proposing a question must be held of higher value than solving it.'},
{avatar: AVATAR4,
  name: 'John von Neumann',
review:'If people do not believe that mathematics is simple, it is only because they do not realize how complicated life is.'},
]

const Testimonials = () => {
  return (
    <section id='quotes'>

     <h5>lil</h5>
     <h2>Quotes</h2>
     <Swiper className="container testimonials__container"
           // install Swiper modules
           modules={[Pagination]}
           spaceBetween={50}
           slidesPerView={1}
           pagination={{ clickable: true }}
           >
    {
      data.map(({avatar,name,review},index) => {
        return(
        <SwiperSlide  key={index} className="testimonial">
        <div className="client__avatar">
         <img src={avatar} alt="avatar" />
        </div>
        <small className='client__name'>{name}</small>
         <p className='client__review'>{review}</p>

      </SwiperSlide>)
      })
    }
       </Swiper> 

    </section>
  )
}


export default Testimonials