import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {BsArrowDownRight} from 'react-icons/bs'
import {useRef} from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_1h39wf9', 'template_cusyzka', form.current, 'lHhc9fvYR9qzT4NBD')
      .then((result) => {
          console.log(result.text);
          alert('Your mail is sent!');
      }, (error) => {
          console.log(error.text);
          alert('Oops... ' + JSON.stringify(error))
      });
    e.target.reset();  
  };

  return (
    <section id='contact'>
      <h5>Contact</h5>
      <h2>Leave Your Message</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>email</h4>
            <h5>zzz@kyzis.xyz</h5>
            <a href="mailto:zzz@kyzis.xyz" target='_blank' rel="noopener noreferrer">send email</a>
          </article>
          <article className="contact__option">
            <h2>Twitter or Linkedin</h2>
            <h4>See Below</h4>
            <BsArrowDownRight className='contact__option-icon'/>
          </article>
        </div>
        {/* END OF OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Name' required/>
          <input type="email" name='email' placeholder='Email' required/>
          <textarea name="message" cols="30" rows="10" placeholder='Leave A Message...' required></textarea>
          <button type='submit' className='btn btn-primary'> SEND</button>
        </form>
      </div>
    </section>
  )
}

export default Contact