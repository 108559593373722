import React, { useState, useEffect } from 'react'
import './portfolio.css'
import BlogFetch from './BlogFetch';

const Portfolio = () => {
  const rss2json ="https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40dr.bankruptcy"
  const [myBlog, setMyBlog] = useState([]);
  useEffect(() => {
    fetch(rss2json)
        .then(res => res.json())
        .then(data => {
            setMyBlog(data)
        })
    }, [rss2json]);

  function displayBlogs() {
      return myBlog.items && myBlog.items.slice(0,6).map(blog => {
          return blog.categories.length > 0 && <BlogFetch key={blog.pubDate} blogData={blog} />
      })
  }

  return (
    <section id='blog'>
      <h5>Blog</h5>
      <h2>Medium</h2>   
      <div className="container portfolio__container">
      {displayBlogs()} 
      </div>
    </section>
  )
}

export default Portfolio