import React from 'react'
import './experience.css'
import {BsTropicalStorm} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='interest'>
      <h5>Skill</h5>
      <h2>Interest</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Domain</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsTropicalStorm  className="experience__details-icon"/>
              <div>
              <h4>Mathematics</h4>
              <small className='text-light'>Stochastic Control, Statistics & Probability, Differential Geometry, Game Theory</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsTropicalStorm  className="experience__details-icon"/>
              <div>
              <h4>Physics</h4>
              <small className='text-light'>Quantum Cryptography, String Theory</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsTropicalStorm  className="experience__details-icon"/>
              <div>
              <h4>Finance</h4>
              <small className='text-light'>Capital Market, Derivatives, Trading, Valuation, Risk, Portfolio Management</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsTropicalStorm  className="experience__details-icon"/>
              <div>
              <h4>Blockchain</h4>
              <small className='text-light'>Byzantine Fault Tolerance, zkSNARK, Tokenomics, NFT, X-to-Earn</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsTropicalStorm  className="experience__details-icon"/>
              <div>
              <h4>Fitness</h4>
              <small className='text-light'>Bodybuilding, Powerlifting, Calisthenics, Nutrition</small>
              </div>
            </article>
          </div>
        </div>
        {/* DOMAIN END */}
        <div className="experience__endend">
        <h3>Development</h3>
          <div className="experience__content">
          <article className='experience__details'>
              <BsTropicalStorm  className="experience__details-icon"/>
              <div>
              <h4>Scientific Computing</h4>
              <small className='text-light'>Matlab, Mathematica, Python, C++</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsTropicalStorm  className="experience__details-icon"/>
              <div>
              <h4>Web3</h4>
              <small className='text-light'>Solidity, Web3js, Rust, PyTeal</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsTropicalStorm  className="experience__details-icon"/>
              <div>
              <h4>Data Science</h4>
              <small className='text-light'>Machine Learning, Julia, PyTorch</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsTropicalStorm  className="experience__details-icon"/>
              <div>
              <h4>Frontend</h4>
              <small className='text-light'>HTML, CSS, JavaScript, Reactjs, SEO</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsTropicalStorm  className="experience__details-icon"/>
              <div>
              <h4>Backend</h4>
              <small className='text-light'>Go, Java, Node.js, SQL, NoSQL, Docker, Kubernetes</small>
              </div>
            </article>
          </div>
          {/* DEV END */}
        </div>
      </div>
    </section>
  )
}

export default Experience