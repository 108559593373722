import React from 'react'
import './nav.css'
import { FaHome } from "react-icons/fa";
import {BsInfoCircle,BsPuzzle,BsCodeSquare,BsMailbox} from "react-icons/bs"
import { useState } from 'react';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href="/#" onClick={()=> setActiveNav('#')} className={activeNav === '#' ? 'active':''}><FaHome /></a>
      <a href="#about" onClick={()=> setActiveNav('#about')} className={activeNav === '#about' ? 'active':''} ><BsInfoCircle /></a>
      <a href="#interest" onClick={()=> setActiveNav('#interest')} className={activeNav === '#interest' ? 'active':''}><BsPuzzle /></a>
      <a href="#blog" onClick={()=> setActiveNav('#blog')} className={activeNav === '#blog' ? 'active':''}><BsCodeSquare /></a>
      <a href="#contact" onClick={()=> setActiveNav('#contact')} className={activeNav === '#contact' ? 'active':''}><BsMailbox /></a>
    </nav>
  )
}

export default Nav