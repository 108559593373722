import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import {GiArchiveResearch} from 'react-icons/gi'
import {GiMoneyStack} from 'react-icons/gi'
import {IoLogoBitcoin} from 'react-icons/io'

// import { WalletLinkConnector } from "@web3-react/walletlink-connector";
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from '@web3-react/core'

// const CoinbaseWallet = new WalletLinkConnector({
//  url: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
//  appName: "Web3-react Demo",
//  supportedChainIds: [1, 3, 4, 5, 42],
// });

// const WalletConnect = new WalletConnectConnector({
//  rpcUrl: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
//  bridge: "https://bridge.walletconnect.org",
//  qrcode: true,
// });

import { ethers } from "ethers";


const startPayment = async ({ether, addr }) => {
  try {
    if (!window.ethereum)
      throw new Error("No crypto wallet found. Please install it.");

    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    ethers.utils.getAddress(addr);
    const tx = await signer.sendTransaction({
      to: addr,
      value: ethers.utils.parseEther(ether)
    });
    console.log({ ether, addr, tx });
  } catch (err) {
    alert(err.message);
  }
};

const About = () => {

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    await startPayment({
      ether: data.get("ether"),
      addr: "0xC5d70fE2A2b10735ED30415E710a3E2846cBfa9C"//data.get("addr")
    });
  };

  const Injected = new InjectedConnector({
    supportedChainIds: [1, 2, 3, 4, 5, 56, 97,137,80001]
   });
   const {
    account,
    activate,
    active,
    chainId,
    deactivate,
    } = useWeb3React();

  const connectButton = ()=>{
    return(
      <button className='btn btn-primary' onClick={() => { activate(Injected) }}>Connect Metamask</button>
    )
  }
  
  const disconnectButton = ()=>{
    return(
      <div>
      <p>Chain ID: {chainId}, account: {account}</p>
      <form onSubmit={handleSubmit}>
      <div>
          <h2>Donate ETH</h2>
          <div className="">
            <div className="my-3">
              {/* <input
                type="text"
                name="addr"
                className="input input-bordered block w-full focus:ring focus:outline-none"
                placeholder="Recipient Address"
              /> */}
            </div>
            <div>
              <input
                name="ether"
                type="number"
                step="0.01"
                min = "0.01"
                placeholder="Amount in ETH"
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary"
          >
            Send
          </button>
      </div>
    </form>
      <button className='btn btn-primary' onClick={deactivate}>Disconnect</button>
      </div>
    )
  }
  return (
    <section id='about'>
      <h5>class About</h5>
      <h2>public:</h2>

      <div className="about__container">
        <div className="about__me">
        <div className="about__me-image">
          <img src={ME} alt="ME-about" />
        </div>
        </div>
        <div className="about__content">
          <div className="about__cards">

          <article className="about__card">
            <GiArchiveResearch className='about__icon' />
            <h5>Academic</h5>
            <small>
              <ul>
                <li>Mathematics</li>
                <li>Physics</li>
                <li>Philosophy</li>
                <li>Statistics</li>
                <li>Computer Science</li>
              </ul>
            </small>
          </article>

          <article className="about__card">
            <GiMoneyStack className='about__icon' />
            <h5>Quant</h5>
            <small>
             <ul>
                <li>Statistical Arbitrage</li>
                <li>Market Making</li>
                <li>Risk Management</li>
                <li>High Frequency Trading</li>
                <li>Data Science</li>
              </ul>
            </small>
          </article>

          <article className="about__card">
            <IoLogoBitcoin className='about__icon' />
            <h5>Blockchain</h5>
            <small>
              <ul>
                <li>Cryptography and Algorithm</li>
                <li>Zero-Knowledge Proof</li>
                <li>Smart Contract</li>
                <li>Protocol Research</li>
                <li>DEX and Defi</li>
                <li>NFT</li>
              </ul>
            </small>
          </article>

          
          </div>
          <p>
          Dr. K holds a Ph.D. in Mathematics, he is also a certified Financial Risk Manager(FRM). He has been working on artificial intelligence and blockchain development since 2014 and 2015 respectively. He also co-designed a first-generation L2-type hybrid exchange protocol. He also built award-winning solutions in wealth management, A.I., blockchain security and insurtech.
          </p>
          <div>
          {/* <button onClick={() => { activate(CoinbaseWallet) }}>Coinbase Wallet</button>
          <button onClick={() => { activate(WalletConnect) }}>Wallet Connect</button> */}
            {active ? disconnectButton() : connectButton()}
          </div>
        </div>
      </div>
    </section>
  )
}


export default About