import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {AiFillMediumSquare} from 'react-icons/ai'
import {AiFillTwitterCircle} from 'react-icons/ai'

const HeaderSocial = () => {
  return (
    <div className="header__socials">
        <a href="https://www.linkedin.com/in/kyzis-tse/" target="_blank" rel="noreferrer"><BsLinkedin /></a>
        <a href="https://github.com/NoEdgeAtLife" target="_blank" rel="noreferrer"><BsGithub /></a>
        <a href="https://medium.com/@Dr.Bankruptcy" target="_blank" rel="noreferrer"><AiFillMediumSquare /></a>
        <a href="https://twitter.com/0xBankruptcy" target="_blank" rel="noreferrer"><AiFillTwitterCircle /></a>
    </div>
  )
}

export default HeaderSocial