import React from 'react'

const BlogFetch = (props) => {
const { title, link, thumbnail, content, pubDate } = props.blogData;

    function blogPost() {

        return <a target="_blank" rel="noopener noreferrer" href={`${link}`}>
                <img src={`${thumbnail}`} alt='' className="portfolio__items-image"></img>
            <div>
                <h3>{truncateText(cleanTitle(title), 0, 90)}</h3><br />
                <p>{truncateText(toText(content), 0, 200) + "..."}</p><br />
                <div className="portfolio__items-cta">
                 <a href={link} className='btn btn-primary' target='_blank' rel="noopener noreferrer">Link</a>
                </div>
                <h4>{convertDate(pubDate)}</h4>
            </div>
        </a>

        }
        function cleanTitle(checkTitle) {
            checkTitle = checkTitle.replace("amp;", "");
            return checkTitle
        }

        function truncateText(text, start, len) {
            return text.length > len ? text.slice(start, len) : text;
        }

        function toText(block) {
            let tag = document.createElement('div');
            tag.innerHTML = block;
            block = tag.innerText;
            return block
        }

        function convertDate(date) {
            let dateArray = date.slice(0, 10).split("-")
            let year = dateArray.shift();
            dateArray.push(year)
            return `Published: ${dateArray.join("/")}`;
        }

    return (
        <article className="portfolio__items">
            {blogPost()}
        </article>
    );
}

export default BlogFetch