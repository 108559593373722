import { createRoot } from 'react-dom/client';
import App from './App'
import './index.css'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers";

function getLibrary(provider) {
    return new Web3Provider(provider);
  }

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <App tab="home" />
    </Web3ReactProvider>
)