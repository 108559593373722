import React from 'react'
import './footer.css'
import {IoLogoTwitter,IoLogoLinkedin} from 'react-icons/io'


const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();
  return (
    <footer id="footer">
      <a href="/#" className='footer__logo'> zKy </a>
      <ul className='permalinks'>
        <li><a href="/#"> Home </a></li>
        <li><a href="#about"> About </a></li>
        <li><a href="#interest"> Intertest </a></li>
        {/* <li><a href="#services"> Services </a></li> */}
        <li><a href="#blog"> Blog </a></li>
        <li><a href="#quotes"> Quotes </a></li>
        <li><a href="#contact"> Contact </a></li>
      </ul>
      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/kyzis-tse/"><IoLogoLinkedin/></a>
        <a href="https://twitter.com/0xBankruptcy"><IoLogoTwitter/></a>
      </div>

      <div className="footer__copyright">
        <small>Kyzis.xyz &copy; {year}  All rights reserved</small>
      </div>
    </footer>
  )
}

export default Footer